<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle">
					<div class="hero-content align-center">
						<span class="hero-title">
							{{ defaults[locale].homepage.headingTitle }}
						</span>
						<span class="hero-subtitle subtitle">
							{{ defaults[locale].homepage.headingSubTitle }}
						</span>
					</div>
				</div>
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #bgImage>
				<picture>
					<source srcset="/images/bg-index.webp" type="image/webp" />
					<source srcset="/images/bg-index.png" />
					<img class="fade-image" src="/images/bg-index.webp" alt="Booking benefits" loading="lazy" />
				</picture>
			</template>
		</main-content>

		<section class="main-content benefits">
			<div class="row">
				<div class="columns column4">
					<div class="content-wrap">
						<h2 class="subtitle">
							{{ defaults[locale].homepage.sectionBenefitsSubtitle }}
						</h2>
						<h2>
							{{ defaults[locale].homepage.sectionBenefitsTitle }}
						</h2>
						<ul>
							<li v-for="(benefit, key) in defaults[locale].homepage.bookingBenefits" :key="key">
								<span>{{ benefit }}</span>
							</li>
						</ul>
						<book-button class="button button-cta reserve-now" aria-label="Reserve now">
							<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ $t('bookNow') }}</span>
						</book-button>
					</div>
				</div>
			</div>
			<picture>
				<source
					v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
					:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
					type="image/webp"
				/>
				<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
				<img
					class="fade-image"
					:src="defaults[locale].homepage.sectionBenefitsImage"
					:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
					loading="lazy"
				/>
			</picture>
		</section>

		<promoblocks
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:items="promoblocksData"
			:amount="6"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:items="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<rooms-overview
			:items="roomsData"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
		/>

		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<pre-footer v-if="page && page.footerSection.length" :footer-section="page.footerSection[0]" />

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>

		<news-overview :items="news">
			<template #section-intro>
				<div class="row row-header align-center">
					<h2 class="subtitle">
						{{ defaults[locale].homepage.sectionNewsTitle }}
					</h2>
					<div v-parse-links v-html="defaults[locale].homepage.sectionNewsContent" />
				</div>
			</template>
		</news-overview>

		<seasons
			:header="defaults[locale].homepage.sectionSeasonsTitle"
			:subtitle="defaults[locale].homepage.sectionSeasonsSubtitle"
			:items="seasonsData"
			:amount="4"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});

const { data: seasonsData } = await useWebsiteFetch('seasons', {
	query: { language: locale.value },
	key: `${locale.value}/seasons`,
});
</script>

<style lang="scss" scoped>
.hero-content {
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;
}

.hero-content span {
	color: #fff;
	font-weight: 400;
	font-size: 72px;
	letter-spacing: 1px;
	width: 100%;
	font-family: $heading-font-family;
	margin: 0 0 10px;
}

.hero-content .hero-subtitle {
	font-size: 24px;
	font-weight: 300;
	font-family: $body-font-family;
	text-transform: none;
	margin: 20px 0 0;

	&::before,
	&::after {
		background: #fff;
	}
}

.benefits .fade-image {
	opacity: 1;
}

.benefits {
	position: relative;
	padding: 60px 0;

	.row {
		z-index: 2;
		position: relative;
	}

	.column4 {
		background: #fff;
		margin: 50px 0;
		position: relative;
		overflow: visible;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: stretch;
		padding: 0;

		.content-wrap {
			z-index: 4;
			position: relative;
			width: 100%;
			height: 100%;
			background: #fff;
			padding: 60px 50px;
			box-shadow: 0 13px 13px rgba(0 0 0 / 25%);
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			inset: 30px -30px -30px 30px;
			background: rgba(249 246 242 / 80%);
			box-shadow: 0 13px 13px rgba(0 0 0 / 25%);
			backdrop-filter: blur(17px);
			z-index: 3;
		}
	}

	.reserve-now {
		svg {
			margin: 0 5px 0 0;
		}
	}
}

.benefits ul {
	list-style: none;
	font-size: 20px;
	margin: 30px 0;
	padding: 0;

	li {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		margin: 20px 0;
		padding: 0;

		&::before {
			content: '';
			background: url('~/assets/images/icon-check.png') no-repeat center center;
			background-size: 40px;
			height: 40px;
			width: 40px;
			display: block;
			margin: 0 30px 0 0;
			position: relative;
			top: auto;
			left: auto;
		}

		span {
			width: calc(100% - 70px);
		}
	}
}

@media (max-width: 1180px) {
	.benefits .column4 {
		width: 50%;
	}
}

@media (max-width: 920px) {
	.benefits {
		overflow: hidden;
		text-align: center;

		ul {
			li {
				font-size: 18px;

				&::before {
					content: '';
					background: url('~/assets/images/icon-check.png') no-repeat center center;
					background-size: 30px;
					height: 30px;
					width: 30px;
					margin: 0 15px 0 0;
				}
			}
		}

		.row {
			max-width: 800px;
			overflow: visible;
			width: 90%;
			display: inline-block;
			margin: 0 auto;
			text-align: left;
		}

		.column4 {
			width: 100%;
		}
	}
}

@media (max-width: 880px) {
	.hero-content .hero-title {
		font-size: 62px;
	}

	.hero-content .hero-subtitle {
		font-size: 24px;
	}
}

@media (max-width: 680px) {
	.hero-content .hero-title {
		font-size: 54px;
	}

	.hero-content .hero-subtitle {
		font-size: 22px;
	}
}

@media (max-width: 520px) {
	.hero-content .hero-title {
		font-size: 46px;
	}

	.hero-content .hero-subtitle {
		font-size: 20px;
	}
}
</style>
